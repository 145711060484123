/* Стили для секции рекламы на автобусах */
.advertising-section {
    padding: 40px 0;
    background-color: #f9f9f9;
}

.advertising-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.advertising-section p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    text-align: center;
}

/* Стили для карточек */
.advertising-section .card {
    transition: transform 0.3s ease;
}

.advertising-section .card:hover {
    transform: translateY(-5px);
}

.advertising-section .card-img-top {
    width: 100%;
    height: 310px; /* Увеличенная высота изображений в карточках */
    object-fit:cover; /* Обрезаем изображение, если оно не вмещается */
    border-bottom: 2px solid #ddd;
}

/* Стили для кнопок */
.advertising-section .btn-primary {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s ease;
}

.advertising-section .btn-primary:hover {
    background-color: #0056b3;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    .advertising-section h2 {
        font-size: 2rem;
    }

    .advertising-section p {
        font-size: 1rem;
    }

    .advertising-section .card-img-top {
        height: 300px; /* Уменьшение высоты изображений на мобильных устройствах */
    }
}

@media (max-width: 576px) {
    .advertising-section h2 {
        font-size: 1.75rem;
    }

    .advertising-section p {
        font-size: 0.9rem;
    }

    .advertising-section .card-img-top {
        height: 250px; /* Еще больше уменьшаем высоту изображений на маленьких экранах */
    }
}
