.news-detail {
  margin-top: 40px;
}

.news-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.news-carousel img {
  border-radius: 25px; /* Закругленные края */
}

.news-content {
  font-size: 20px;
  line-height: 1.6;
  color: #555;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .news-title {
    font-size: 2rem;
  }

  .news-content {
    font-size: 20px;
  }
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
  .news-title {
    font-size: 1.5rem; /* Уменьшаем размер заголовка */
    text-align: center; /* Центрируем заголовок на мобильных */
  }

  .news-detail {
    padding: 10px; /* Уменьшаем отступы на мобильных устройствах */
  }

  .news-carousel {
    margin-bottom: 20px; /* Добавляем отступ снизу после карусели */
  }

  .news-carousel img {
    height: 300px; /* Уменьшаем высоту изображения на мобильных */
    width: 300px; /* Уменьшаем ширину изображения на мобильных */
    object-fit: cover;
  }

  .news-content {
    font-size: 16px; /* Уменьшаем размер текста на мобильных */
    text-align: justify; /* Выровнять текст по ширине */
  }
}

@media (max-width: 576px) {
  .news-carousel img {
    height: 250px; /* Еще больше уменьшаем высоту изображения на очень маленьких экранах */
    width: 250px; /* Еще больше уменьшаем ширину изображения на очень маленьких экранах */
  }

  .news-title {
    font-size: 1.25rem; /* Еще больше уменьшаем размер заголовка на очень маленьких экранах */
  }
}