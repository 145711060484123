.boardContainer {
    padding: 20px;
}

.heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.subheading {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
}

.directors {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.director {
    display: flex;
    flex-direction: column;
}

.name {
    font-weight: bold;
    font-size: 24px;
    color: black;
}

.position {
    font-size: 20px;
    color: grey;
}
