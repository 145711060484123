.PressCenter {
    padding-left: 1px;
  }
  
  .PressCenter h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .news-list {
    margin-top: 0px;
  }
  