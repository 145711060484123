.lost-and-found-info {
  padding: 20px;
  background-color: #f9f9f9;
}

.lost-and-found-info h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.lost-and-found-info .info-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.lost-and-found-info p {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.6;
}
.content-text-info p{
  font-size: 20px;
}