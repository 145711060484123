/* Общий стиль для всего компонента */
.home-carousel-container,
.home-card-group-custom,
.home-custom-card {
}

@media (max-width: 1200px) {
    .home-carousel-container {
        height: 400px;
    }

    .home-carousel-container .home-carousel-item img {
        height: 400px;
        object-fit: cover;
    }

    .home-carousel-caption {
        bottom: 20px;
        font-size: 16px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        color: white;
        z-index: 2; /* Устанавливаем, чтобы текст был выше изображения */
        position: relative; /* Устанавливаем относительное позиционирование */
    }

    .home-carousel-caption h3 {
        font-size: 20px;
    }

    .home-carousel-caption p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .home-carousel-container {
        height: 300px;
    }

    .home-carousel-container .home-carousel-item img {
        height: 300px;
    }

    .home-carousel-caption {
        bottom: 10px;
        font-size: 14px;
        padding: 8px;
        z-index: 2; /* Устанавливаем, чтобы текст был выше изображения */
        position: relative; /* Устанавливаем относительное позиционирование */
    }

    .home-carousel-caption h3 {
        font-size: 18px;
    }

    .home-carousel-caption p {
        font-size: 12px;
    }

    /* Обновление карточек для мобильных экранов */
    .home-custom-card {
        width: 100%; /* Карточки занимают всю ширину на мобильных экранах */
        margin-bottom: 20px; /* Добавляем отступы между карточками */
    }

    .home-custom-card img {
        width: 100%; /* Изображения занимают всю ширину карточки */
        height: auto; /* Поддержание пропорций изображений */
    }

    .home-card-group-custom {
        flex-direction: column; /* Размещаем карточки вертикально */
    }
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

.home-card-group-custom {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Добавляем отступы между карточками */
    justify-content: space-between;
}

.home-custom-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 400px;
    cursor: pointer;
    background-color: #fff;
}

.home-custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 992px) {
    .home-custom-card {
        width: 100%;
    }
}

.home-date-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    z-index: 3; /* Устанавливаем, чтобы дата была выше всего */
}

.home-date-overlay .home-day {
    font-size: 28px;
    font-weight: bold;
    display: block;
}

.home-date-overlay .home-month {
    font-size: 18px;
    text-transform: uppercase;
}

.home-carousel-item img {
    z-index: 1; /* Устанавливаем изображение ниже остальных элементов */
    position: relative;
    border-radius: 15px; /* Закругленные углы изображений */
    transition: transform 0.3s ease-in-out; /* Добавляем плавную анимацию на увеличение */
}

.home-carousel-item img:hover {
    transform: scale(1.05); /* Легкое увеличение изображения при наведении */
}
