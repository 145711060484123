/* ForPassengers.css */

/* Применение общего шрифта для всего компонента */
.for-passengers-container {
}

/* Стили для заголовков */
.for-passengers-container h5 {
    font-size: 30px;
    color: #333;
    transition: color 0.3s, text-decoration 0.3s; /* Плавный переход цвета и подчёркивания */
}

/* Стили для описания */
.for-passengers-container p {
    font-size: 20px;
    color: #555;
}

/* Стили для иконок */
.for-passengers-container .image-icon {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    cursor: pointer; /* Изменение курсора на "руку" при наведении на иконку */
}

/* Пример для дополнительной настройки */
.for-passengers-container .custom-row {
    margin-bottom: 30px;
}

/* Стили для поведения при наведении */
.for-passengers-container h5:hover {
    color: #09f; /* Цвет текста при наведении */
    text-decoration: underline; /* Подчеркивание текста при наведении */
    cursor: pointer; /* Курсор "рука" при наведении на заголовок */
}

.for-passengers-container .image-icon:hover {
    cursor: pointer; /* Курсор "рука" при наведении на иконку */
}
