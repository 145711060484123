/* Общие стили для слайдера */
.slider-container {
    margin-bottom: 50px;
}

.custom-carousel {
    width: 1320px;
    height: 540px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; /* Центрирует изображение в контейнере */
    border-radius: 15px;
}

.slider-title {
    color: #000;
    margin-bottom: 10px;
    text-align: center;
}

.slider-subtitle {
    color: #09F;
    margin-bottom: 30px;
    text-align: center;
}

/* Стили для информационного блока */
.info-section {
    background-color: #09F;
    padding: 50px 0;
}

.info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.info-icon {
    width: 100px; /* Измените размер иконок по вашему усмотрению */
    height: 100px;
    margin-bottom: 10px;
}

.info-inline {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-text-large {
    font-size: 36px;
    color: #fff;
    margin-bottom: 5px;
    margin-right: 10px; /* Отступ между процентом и текстом */
}

.info-text-small {
    font-size: 24px;
    color: #000;
}

.info-text-medium {
    font-size: 28px;
    color: #fff;
}

/* Для адаптивности */
@media (max-width: 768px) {
    .custom-carousel {
        width: 100%;
        height: auto; /* Высота будет автоматически адаптироваться */
    }

    .slider-image {
        height: auto;
        object-fit: cover;
        object-position: center; /* Центрирует изображение в контейнере */
    }

    .info-inline {
        flex-direction: column;
    }

    .info-text-large {
        margin-right: 0;
    }
}
