.heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.timeline {
    position: relative;
    margin-left: 20px;
    padding-left: 40px; /* Увеличено для более заметной линии */
    border-left: 2px solid transparent; /* Убираем линию от контейнера, оставляя только между элементами */
}

.timelineItem {
    position: relative;
    margin-bottom: 40px; /* Увеличено расстояние между элементами */
    padding-left: 20px;
}

.dot {
    position: absolute;
    left: -22px; /* Отступ слева для большой точки */
    top: 0;
    width: 16px; /* Увеличен размер точки */
    height: 16px;
    background-color: #09f; /* Цвет точки */
    border-radius: 50%;
    border: 3px solid #fff; /* Толщина обводки точки */
    box-shadow: 0 0 0 2px #09f; /* Создает эффект обводки */
}

.timelineItem::after {
    content: '';
    position: absolute;
    left: -15px; /* Расположение линии по центру точки */
    top: 20px; /* Начало линии под точкой */
    bottom: -20px; /* Конец линии над следующей точкой */
    width: 2px;
    background-color: #09f; /* Цвет линии */
}

.content {
    margin-left: 20px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.description {
    font-size: 16px;
    color: #555;
}
