/* NewsList.css */
.news-list {
    margin-top: 20px;
  }
  
  .news-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .news-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .news-item p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  