/* Основные стили для секции IntroSection */
.intro-section {
    padding: 60px 0; /* Увеличенные отступы сверху и снизу */
    background-color: #ffffff; /* Цвет фона */
    text-align: center; /* Центрирование текста */
}

.intro-section h1 {
    font-size: 3rem; /* Увеличенный размер заголовка */
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.3; /* Растягиваем текст заголовка */
    color: #333; /* Цвет текста заголовка */
}

.intro-section p {
    font-size: 1.5rem; /* Увеличенный размер текста */
    line-height: 1.7; /* Растягиваем текст параграфа */
    color: #666; /* Цвет текста параграфа */
    max-width: 800px; /* Максимальная ширина текста */
    margin: 0 auto; /* Центрирование текста */
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    .intro-section h1 {
        font-size: 2.5rem; /* Уменьшение размера заголовка на планшетах */
    }

    .intro-section p {
        font-size: 1.2rem; /* Уменьшение размера текста на планшетах */
        line-height: 1.6; /* Немного уменьшенное растяжение текста */
    }
}

@media (max-width: 576px) {
    .intro-section h1 {
        font-size: 2rem; /* Уменьшение размера заголовка на мобильных */
    }

    .intro-section p {
        font-size: 1rem; /* Уменьшение размера текста на мобильных */
        line-height: 1.5; /* Еще большее уменьшение растяжения текста */
    }
}
