.partners-section {
    text-align: center; /* Центрирование текста */
  }
  
  .partners-section h2 {
    font-size: 2.5rem; /* Увеличиваем размер заголовка */
    margin-bottom: 1.5rem;
    color: #333; /* Основной цвет текста */
  }
  
  .partners-section p {
    font-size: 1.25rem; /* Увеличиваем размер текста */
    margin-bottom: 2rem;
    color: #666; /* Цвет для абзацев */
  }
  
  /* Flex-контейнер для выравнивания логотипов по горизонтали */
  .partners-section .row {
    display: flex;
    justify-content: center; /* Центрируем логотипы горизонтально */
    align-items: center; /* Выравниваем логотипы по вертикали */
  }
  
  .partners-section img {
    max-width: 200px; /* Ограничиваем ширину логотипов */
    max-height: 150px; /* Ограничиваем высоту логотипов */
    width: auto; /* Сохраняем пропорции */
    height: auto;
    transition: transform 0.3s ease-in-out; /* Анимация при наведении */
  }
  
  .partners-section img:hover {
    transform: scale(1.05); /* Небольшое увеличение при наведении */
  }
  
  @media (max-width: 768px) {
    .partners-section img {
      max-width: 150px; /* Уменьшаем ширину логотипов на мобильных устройствах */
      max-height: 100px; /* Уменьшаем высоту логотипов на мобильных устройствах */
    }
  
    .partners-section h2 {
      font-size: 2rem; /* Уменьшаем размер заголовка на мобильных устройствах */
    }
  
    .partners-section p {
      font-size: 1rem; /* Уменьшаем размер текста на мобильных устройствах */
    }
  
    .partners-section .row {
      flex-direction: column; /* Ставим логотипы в столбик на мобильных устройствах */
    }
  }
  