.carouselContainer {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

/* Карточка вакансии */
.vacancyCard {
    display: flex !important;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 950px; /* Ширина карточки для десктопной версии */
    overflow: hidden;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Эффект при наведении на карточку */
.vacancyCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Контейнер для фото */
.photoContainer {
    flex-shrink: 0;
    width: 150px; /* Фиксированная ширина для фото в десктопной версии */
    height: 150px; /* Фиксированная высота для фото в десктопной версии */
    border-radius: 25%; /* Закругленные углы для фото */
    margin-right: 20px;
    overflow: hidden;
    position: relative;
}

/* Псевдоэлемент для градиентной рамки */
.photoContainer::before {
    content: '';
    position: absolute;
    top: -5px; /* Сдвигаем на 5px вверх */
    left: -5px; /* Сдвигаем на 5px влево */
    width: calc(100% + 10px); /* Увеличиваем ширину */
    height: calc(100% + 10px); /* Увеличиваем высоту */
    border-radius: 25%; /* Закругленные углы псевдоэлемента */
    padding: 5px; /* Толщина рамки */
    background: linear-gradient(135deg, #BE93C5 0%, #7BC6CC 100%); /* Градиент для рамки */
    z-index: 1;
}

/* Стили для фото вакансии */
.vacancyPhoto {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top; /* Показываем верхнюю часть изображения */
    border-radius: 25%; /* Закругленные углы для фото */
    z-index: 2;
    position: relative; /* Поверх рамки */
}

/* Информация о вакансии */
.vacancyInfo {
    flex-grow: 1;
    padding-left: 20px;
}

/* Название вакансии */
.vacancyTitle {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: #333;
}

/* Описание вакансии */
.vacancyDescription {
    font-size: 1.5rem;
    color: #424040;
    margin: 10px 0;
}

/* Требования к вакансии */
.vacancyRequirements {
    font-size: 1.2rem;
    color: #777;
}

/* Убираем стиль стрелок slick-carousel */
.slick-prev:before, .slick-next:before {
    color: transparent; /* Делаем стрелки прозрачными */
    opacity: 0; /* Полностью скрываем стрелки */
}

/* Стили для пользовательских стрелок */
.nextArrow,
.prevArrow {
    position: absolute;
    bottom: -30px; /* Расположить стрелки ниже карусели */
    transform: translateX(-50%); /* Центрирование стрелок по горизонтали */
    background-color: transparent; /* Убираем фон */
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
}

.nextArrow {
    right: 55%; /* Смещение вправо от центра */
}

.prevArrow {
    right: 45%; /* Смещение влево от центра */
}

.nextArrow:hover,
.prevArrow:hover {
    background-color: transparent; /* Без изменений фона при наведении */
}

/* Псевдоэлементы для пользовательских стрелок */
.nextArrow::before,
.prevArrow::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border: solid black; /* Черные стрелки */
    border-width: 0 3px 3px 0;
    padding: 5px;
    transform: rotate(45deg);
}

.nextArrow::before {
    transform: rotate(-45deg);
}

.prevArrow::before {
    transform: rotate(135deg);
}

/* Адаптивный дизайн для планшетов и телефонов */
@media (max-width: 768px) {
    .vacancyCard {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 410px; /* Ширина карточки для адаптивной версии */
        padding: 20px;
    }

    .photoContainer {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100px; /* Ширина фото для адаптивной версии */
        height: 100px; /* Высота фото для адаптивной версии */
    }

    .vacancyTitle {
        font-size: 1.3rem;
    }

    .vacancyDescription {
        font-size: 1rem;
    }

    .vacancyRequirements {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .vacancyTitle {
        font-size: 1.1rem;
    }

    .vacancyDescription {
        font-size: 0.9rem;
    }

    .vacancyRequirements {
        font-size: 0.8rem;
    }
}
