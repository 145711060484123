.about-video-section {
    margin-top: 20px;
}

.about-block,
.video-block {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-block {
    background-color: #FF0; /* Задаем цвет фона */
    height: 100%; /* Растягиваем по высоте */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Центрируем содержимое по вертикали */
    padding: 20px; /* Отступы внутри блока */
    border-radius: 15px; /* Закругляем углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Добавляем тень */
    
}

.video-block {
    background-color: #FFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.video-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.video-button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    color: #8b8787;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: none;
    font-size: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-container {
    max-width: 100%;
    max-height: 80vh;
}
