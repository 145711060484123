/* Основной контейнер для секции руководителей */
.leadershipContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

/* Карточка руководителя */
.leaderCard {
    display: flex;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 950px; /* Ширина карточки для десктопной версии */
    overflow: hidden;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Эффект при наведении на карточку */
.leaderCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Контейнер для фото */
.photoContainer {
    flex-shrink: 0;
    width: 250px; /* Фиксированная ширина для фото в десктопной версии */
    height: 350px; /* Фиксированная высота для фото в десктопной версии */
    border-radius: 5%; /* Закругленные углы для фото */
    margin-right: 20px;
    overflow: hidden;
    position: relative;
}

/* Стили для фото руководителя */
.leaderPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Изображение сохраняет свои пропорции и заполняет контейнер */
    object-position: top; /* Сдвигает изображение к верхней части, показывая лица */
}

/* Информация о руководителе */
.leaderInfo {
    flex-grow: 1;
}

/* Имя руководителя */
.leaderName {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    color: #333; /* Цвет текста имени */
}

/* Должность руководителя */
.leaderPosition {
    font-size: 1.1rem;
    color: #777; /* Цвет текста должности */
    margin: 5px 0;
}

/* Описание руководителя */
.leaderDescription {
    font-size: 0.9rem;
    color: #555; /* Цвет текста описания */
    margin: 10px 0 0 0;
}

/* Адаптивный дизайн для планшетов и телефонов */
@media (max-width: 768px) {
    .leaderCard {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 410px; /* Ширина карточки для адаптивной версии */
        padding: 20px;
    }

    .photoContainer {
        margin-right: 0;
        margin-bottom: 15px;
        width: 150px; /* Ширина фото для адаптивной версии */
        height: 225px; /* Высота фото для портретного формата */
    }

    .leaderName {
        font-size: 1.3rem;
    }

    .leaderPosition {
        font-size: 1rem;
    }

    .leaderDescription {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .leaderCard {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px;
    }

    .photoContainer {
        margin-right: 0;
        margin-bottom: 10px;
        width: 200px; /* Сделать фото чуть шире на очень маленьких экранах */
        height: 300px; /* Увеличенная высота для портретного формата */
    }

    .leaderName {
        font-size: 1.1rem;
    }

    .leaderPosition {
        font-size: 0.9rem;
    }

    .leaderDescription {
        font-size: 0.8rem;
    }
}
