.full-width-image-container {
    width: 100vw;
    height: 540px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.full-width-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Адаптация для экранов до 1200px */
@media (max-width: 1200px) {
    .full-width-image-container {
        height: 450px; /* Уменьшаем высоту для средних экранов */
    }
}

/* Адаптация для экранов до 992px */
@media (max-width: 992px) {
    .full-width-image-container {
        height: 400px; /* Уменьшаем высоту для планшетов */
    }
}

/* Адаптация для экранов до 768px */
@media (max-width: 768px) {
    .full-width-image-container {
        height: 350px; /* Еще больше уменьшаем высоту для небольших экранов */
    }
}

/* Адаптация для экранов до 576px */
@media (max-width: 576px) {
    .full-width-image-container {
        height: 300px; /* Минимальная высота для мобильных устройств */
    }
}
