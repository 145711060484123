.partnerBlock {
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
}

.partnerLogo {
    width: 150px;
    height: auto;
    margin-right: 20px;
}

.partnerText {
    display: flex;
    flex-direction: column;
}

.partnerName {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.partnerDescription {
    font-size: 1.2rem;
    color: #777;
}

.navLink {
    font-size: 24px; /* Задайте нужный размер шрифта */
}
