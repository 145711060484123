/* Общий контейнер */
.complianceContainer {
    padding: 20px;
    background-color: #f9f9f9;
}

/* Заголовки */
.heading {
    font-size: 32px;
    font-weight: bold;
    color: #003366;
    margin-bottom: 20px;
}

/* Горячая линия */
.hotlineSection {
    margin-bottom: 30px;
}

.hotlineContent {
    width: 100%;
}

.textContent {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
}

/* Изображения */
.imageSmall {
    flex-shrink: 0;
    width: 350px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Принципы и гарантии */
.principles,
.guarantees {
    margin-bottom: 20px;
}

.textWithImage {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.textWithImage ul {
    flex: 1;
    list-style-type: disc;
    margin: 0;
    padding: 0 20px 0 0;
}

/* Контактный блок */
.contactBlock {
    background-color: #007bff;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.contactBlock h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.contactBlock ul {
    list-style: none;
    padding: 0;
}

.contactBlock li {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.emailLink {
    color: #fff;
    text-decoration: underline;
}

/* Раздел Комплаенс-офицера */
.complianceSection {
    margin-top: 40px;
}

.subheading {
    font-size: 28px;
    font-weight: bold;
    color: #003366;
    margin-bottom: 15px;
}

.list {
    list-style: none;
    padding: 0;
}

.list li {
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.list a {
    color: #003366;
    text-decoration: none;
}

.list a:hover {
    text-decoration: underline;
}

.listItem {
    margin-bottom: 10px;
    font-size: 1.1rem;
}

/* Кнопка для сворачивания списка документов */
.toggleButton {
    background-color: #003366;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 15px;
    transition: background-color 0.3s;
}

.toggleButton:hover {
    background-color: #002244;
}

/* Раздел Сертификаты деп */
.certificatesSection {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.certificatesList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.certificate {
    flex: 1;
    min-width: 250px;
    text-align: center;
}

.certificateImage {
    width: 100%;
    height: auto;
    max-width: 250px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .textWithImage {
        flex-direction: column;
    }

    .imageSmall {
        width: 100%;
        margin-top: 20px;
    }

    .complianceSection,
    .certificatesSection {
        padding: 15px;
    }

    .list li {
        font-size: 1rem;
    }

    .certificatesList {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 28px;
    }
    
    .subheading {
        font-size: 24px;
    }
    
    .list li {
        font-size: 0.95rem;
    }
}
