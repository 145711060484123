/* Стили для мобильного меню */
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%; /* Меню теперь занимает 50% ширины экрана */
    height: 100%;
    background-color: rgba(51, 51, 51, 0.9); /* Прозрачный фон */
    z-index: 2000; /* Увеличен z-index для предотвращения перекрытия */
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Скрываем меню по умолчанию */
}

/* Активное состояние мобильного меню */
.mobile-menu.active {
    transform: translateX(0);
}

.mobile-header-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.mobile-header-top .social-icons img {
    width: 30px;
    height: 30px;
    margin: 5px;
}

.mobile-header-top .header-contact,
.mobile-header-top .language-switcher {
    margin: 10px 0;
    color: #fff;
}

/* Стили для ссылок в мобильном меню */
.mobile-menu .custom-nav-link {
    color: #fff;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #444;
}

.mobile-menu .custom-nav-link:hover {
    background-color: #555;
    color: #FF0;
}

/* Стили для навигационных ссылок */
.custom-nav-link {
    font-size: 22px;
    color: white;
}

.custom-nav-link:hover {
    color: #FF0;
}

/* Стили для кнопок и текста */
.custom-font {
    font-size: 22px;
}

.custom-font-button {
    font-size: 22px;
    margin-left: 10px;
}

/* Стили для контента */
.mobile-content {
    margin-top: -90px; /* отступ сверху для учета высоты хедера */
}

/* Стили для верхней части навигации (соцсети, телефон, переключатель языка) */
.header-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50px; /* Уменьшена высота хедера для мобильных устройств */
    background-color: #09F; /* Цвет фона хедера */
    z-index: 1000; /* Убедитесь, что z-index у хедера ниже, чем у мобильного меню */
    justify-content: center; /* Выравниваем содержимое по вертикали */
}

.header-top img {
    height: 20px; /* Уменьшена высота логотипа */
    width: auto; /* Сохраняем пропорции логотипа */
}

.header-top .social-icons,
.header-top .header-contact,
.header-top .language-switcher {
    margin-bottom: 5px;
    margin-right: 110px;
}

.header-top .language-switcher {
    padding-left: 50px;
}

.social-icons a {
    margin-right: 10px;
}

.social-icons img {
    width: 30px;
    height: 30px;
}

.header-contact img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
}

.header-contact {
    color: #FFF;
    font-size: 18px;
}

.language-switcher .language-btn {
    color: #FFF;
    font-size: 18px;
    text-decoration: none;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    .header-top {
        height: 50px; /* Уменьшаем высоту хедера для мобильных устройств */
        justify-content: center; /* Выравниваем содержимое по вертикали */
    }

    .header-top img {
        height: 20px; /* Уменьшаем размер логотипа */
        width: auto; /* Сохраняем пропорции логотипа */
    }

    .social-icons img {
        width: 24px;
        height: 24px;
    }

    .header-contact {
        margin-bottom: 5px;
        font-size: 16px;
    }

    .header-contact img {
        width: 20px;
        height: 20px;
    }

    .language-switcher .language-btn {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .header-top {
        height: 40px; /* Еще больше уменьшаем высоту для очень маленьких экранов */
    }

    .header-top img {
        height: 25px; /* Соответственно уменьшаем размер логотипа */
        width: auto; /* Сохраняем пропорции логотипа */
    }

    .social-icons img {
        width: 20px;
        height: 20px;
    }

    .header-contact {
        font-size: 14px;
    }

    .header-contact img {
        width: 18px;
        height: 18px;
    }

    .language-switcher .language-btn {
        font-size: 14px;
    }
}

.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* затемненный фон */
    z-index: 998; /* Должен быть ниже, чем .mobile-menu */
  }
  
  
  
  .mobile-menu.active {
    left: 0;
  }
  