/* Общие стили для CarouselBox */
.carouselbox-container {
    width: 100vw;
    margin: -10px auto;
}

/* Стили для изображений в карусели */
.carouselbox-img {
    width: 100%;
    height: 720px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Анимация при наведении на изображение */
.carouselbox-img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Стили для текста в карусели */
.carouselbox-caption-custom {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: #fff;
    z-index: 10;
}

/* Настройки шрифтов и размера текста */
.carouselbox-caption-custom h3 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ff0;
    letter-spacing: 0.05em;
}

.carouselbox-caption-custom p {
    font-size: 22px;
    margin-bottom: 0;
    letter-spacing: 0.05em;
}

/* Адаптация для экранов до 1200px */
@media (max-width: 1200px) {
    .carouselbox-container {
        height: 400px;
    }

    .carouselbox-container .carouselbox-img {
        height: 400px;
    }

    .carouselbox-caption-custom {
        bottom: 10px;
        font-size: 16px;
    }

    .carouselbox-caption-custom h3 {
        font-size: 20px;
    }

    .carouselbox-caption-custom p {
        font-size: 14px;
    }
}

/* Стили для карточек */
.custom-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
    transition: transform 0.2s ease-in-out; /* Анимация при наведении */
    width: 400px; /* Фиксированная ширина */
    height: auto; /* Автоматическая высота */
    cursor: pointer;
}

.custom-card img {
    width: 100%; /* Изображение занимает всю ширину карточки */
    height: auto; /* Поддержание пропорций изображений */
    object-fit: cover; /* Убедитесь, что изображение масштабируется с сохранением пропорций */
}

/* Анимация карточек */
.custom-card:hover {
    transform: translateY(-5px); /* Анимация движения */
}

/* Стили для даты на карточках */
.date-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    z-index: 3; /* Устанавливаем, чтобы дата была выше всего */
}

.date-overlay .day {
    font-size: 28px;
    font-weight: bold;
    display: block;
}

.date-overlay .month {
    font-size: 18px;
    text-transform: uppercase;
}

/* Адаптация для экранов до 768px */
@media (max-width: 768px) {
    .carouselbox-container {
        /* Не меняем размер для мобильных устройств */
    }

    .carouselbox-container .carouselbox-img {
        /* Не меняем размер для мобильных устройств */
    }

    .carouselbox-caption-custom {
        bottom: 10px;
        font-size: 14px;
        padding: 10px;
    }

    .carouselbox-caption-custom h3 {
        font-size: 18px;
    }

    .carouselbox-caption-custom p {
        font-size: 12px;
    }

    /* Убедимся, что карточки сохраняют свои размеры на мобильных устройствах */
    .custom-card {
        width: 400px; /* Фиксированная ширина сохраняется */
        height: auto; /* Поддержание высоты карточек */
    }
}
/* Общие стили для модального окна */
.modal-content {
    max-width: 90%; /* Устанавливаем максимальную ширину модального окна */
    margin: 0 auto; /* Центрируем модальное окно */
    border-radius: 15px; /* Закругленные углы модального окна */
}

.modal-body img {
    width: 100%; /* Изображение занимает всю ширину модального окна */
    height: auto; /* Поддержание пропорций изображений */
    object-fit: cover; /* Убедитесь, что изображение масштабируется с сохранением пропорций */
    border-radius: 15px; /* Закругленные углы изображения */
}

/* Убедимся, что изображения в модальном окне не сжимаются на мобильных устройствах */
@media (max-width: 768px) {
    .modal-body img {
        width: 100%; /* Изображение занимает всю ширину на мобильных устройствах */
        height: auto; /* Поддержание пропорций изображений */
    }
}

/* Дополнительные стили для формы в модальном окне */
.modal-body form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Расстояние между элементами формы */
}

.modal-body input[type="file"] {
    width: 100%; /* Ширина загрузчика файлов на всю ширину */
    margin-bottom: 10px;
}

.modal-body textarea {
    width: 100%; /* Ширина текстового поля на всю ширину */
    resize: vertical; /* Разрешить вертикальное изменение размера */
}

.modal-body .btn {
    align-self: flex-end; /* Выравнивание кнопки по правому краю */
}

/* CarouselBox.css */

/* Стили для стрелок слайдера */
.carousel-control-prev, .carousel-control-next {
    background-color: rgba(255, 255, 255, 0.7); /* Белый слегка прозрачный фон */
    border-radius: 50%; /* Круглая форма */
    width: 40px; /* Ширина стрелки */
    height: 40px; /* Высота стрелки */
    display: flex; /* Flexbox для центрирования иконки */
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
    position: absolute; /* Абсолютное позиционирование для точного размещения */
    top: 50%; /* Центрирование по вертикали */
    transform: translateY(-50%); /* Сдвиг для идеального центрирования */
    transition: background-color 0.3s ease; /* Плавный переход фона при наведении */
}

.carousel-control-prev:hover, .carousel-control-next:hover {
    background-color: rgba(255, 255, 255, 0.9); /* Более непрозрачный фон при наведении */
}

/* Стили для иконок стрелок (если иконки находятся внутри <span> или <svg>) */
.carousel-control-prev-icon, .carousel-control-next-icon {
    filter: invert(1); /* Инвертировать цвет, чтобы иконки были видимы на белом фоне */
}
