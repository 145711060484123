/* Стили для секции аренды автобусов */
.bus-rental-section {
    padding: 40px 0;
    background-color: #f8f9fa;
}

.bus-rental-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.bus-rental-section p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    text-align: center;
}

/* Стили для карточек */
.bus-rental-section .card {
    transition: transform 0.3s ease;
}

.bus-rental-section .card:hover {
    transform: translateY(-5px);
}

.bus-rental-section .card-img-top {
    width: 100%;
    height: 225px; /* Фиксированная высота для изображений */
    object-fit: cover; /* Обрезаем изображение, если оно не вмещается */
    border-bottom: 2px solid #ddd;
}

/* Стили для кнопок */
.bus-rental-section .btn-primary {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s ease;
}

.bus-rental-section .btn-primary:hover {
    background-color: #0056b3;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    .bus-rental-section h2 {
        font-size: 2rem;
    }

    .bus-rental-section p {
        font-size: 1rem;
    }

    .bus-rental-section .card-img-top {
        height: 250px; /* Уменьшаем высоту изображения на мобильных устройствах */
    }
}

@media (max-width: 576px) {
    .bus-rental-section h2 {
        font-size: 1.75rem;
    }

    .bus-rental-section p {
        font-size: 0.9rem;
    }

    .bus-rental-section .card-img-top {
        height: 200px; /* Еще больше уменьшаем высоту изображения на маленьких экранах */
    }
}
