/* Общий стиль для компонента NewsSection */
.news-section-container {
}

.news-section-container h2 {
    font-size: 28px;
    color: #333;
}

.card-group-custom {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
}

.custom-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
}

.custom-card:hover {
    transform: translateY(-5px);
}

.date-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    z-index: 3;
}

.date-overlay .day {
    font-size: 28px;
    font-weight: bold;
    display: block;
}

.date-overlay .month {
    font-size: 18px;
    text-transform: uppercase;
}

/* Адаптация для экранов до 768px */
@media (max-width: 768px) {
    .news-section-container h2 {
        font-size: 24px;
    }

    .custom-card {
        width: 100%;
    }

    .card-group-custom {
        gap: 10px;
    }
}
