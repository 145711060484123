.fleet-section-container {
    text-align: center;
    padding: 40px 0;
}

.fleet-col {
    display: flex;
    justify-content: center;
    flex-basis: calc(100% / 4); /* 4 карточки в ряд на больших экранах */
    max-width: 300px; /* Ограничиваем размер */
    padding: 10px;
}

@media (max-width: 992px) {
    .fleet-col {
        flex-basis: calc(100% / 2); /* 2 карточки в ряд на средних экранах */
    }
}

@media (max-width: 576px) {
    .fleet-col {
        flex-basis: 100%; /* 1 карточка в ряд на маленьких экранах */
    }
}

.fleet-card {
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    width: 100%;
    max-width: 300;
    cursor: pointer;
}

.fleet-card:hover {
    transform: scale(1.05);
}
