/* Стили для изображений в навигационной панели */
img {
    height: 80px;
    width: auto; /* Сохраняет пропорции */
}

/* Стили для навигационных ссылок */
.custom-nav-link {
    font-size: 22px;
    color: white;
}

.custom-nav-link:hover {
    color: #FF0;
}

/* Стили для кнопок и текста */
.custom-font {
    font-size: 22px;
}

.custom-font-button {
    font-size: 22px;
    margin-left: 10px;
}

/* Стили для контента */
.content {
    flex: 1;
    padding-top: 150px; /* Увеличил padding-top для учета высоты хедера */
    /* Подстраивается под высоту Navbar */
    min-height: calc(100vh - 150px); /* Высота окна минус высота хедера */
    box-sizing: border-box;
}

/* Стили для верхней части навигации (соцсети, телефон, переключатель языка) */
.header-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80px; /* Фиксированная высота хедера */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-top .social-icons,
.header-top .header-contact,
.header-top .language-switcher {
    margin-bottom: 10px;
    margin-right: 150px; /* Сохранено как вы просили */
}

.header-top .language-switcher {
    padding-left: 95px; /* Сохранено как вы просили */
}

.social-icons a {
    margin-right: 15px;
}

.social-icons img {
    width: 30px;
    height: 30px;
}

.header-contact img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
}

.header-contact {
    color: #FFF;
    font-size: 18px;
}

.language-switcher .language-btn {
    color: #FFF;
    font-size: 18px;
    text-decoration: none;
}
.phone-link{
    color: #FFF;
}

.desktop-content {
    margin-top: -65px; /* отступ сверху для учета высоты хедера */
}

@media only screen and (max-width: 768px) {
    img {
      width: auto; /* ширина будет подстраиваться автоматически */
      height: 55px; /* установите нужную высоту логотипа для мобильной версии */
    }
  }
