.footer {
    background-color: #f9f9f9;
    padding: 40px 20px;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-section h4 {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  .footer-section ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a img {
    width: 24px;
    height: 24px;
  }
  
  .footer-bottom p {
    font-size: 14px;
    color: #666;
  }
  
  .footer-section ul li img {
    width: 120px; /* Размер иконок для App Store и Google Play */
    margin-right: 10px;
  }
  